import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import ContentAnchor from '@papertrailio/react-web-core/components/ContentAnchor'

import Layout from '../components/Layout'
import Link from '../components/Link'
import HeaderContent from '../components/HeaderContent'

import slug from '../utils/slug'

const NotFoundPage = ({ data }) => (
  <Layout>
    <ContentContainer>
      <ContentAnchor />

      <h1>Page Not Found</h1>
      <p>Ooops. You just hit a page that does not exist...</p>

      <p>
        Perhaps you are looking for product information by one of these
        manufacturers?
      </p>

      <ul>
        {data.allProduct.distinct.map(manufacturer => (
          <li key={manufacturer}>
            <Link to={`/${slug(manufacturer)}/`}>{manufacturer}</Link>
          </li>
        ))}
      </ul>
    </ContentContainer>
  </Layout>
)

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    allProduct {
      distinct(field: manufacturer)
    }
  }
`
